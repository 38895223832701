import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path:'/home',
    name:"main",
    component: () => import("../components/MainView.vue"),
  },
  {
    path:'/filetransfer',
    name:'filetransfer',
    component: () => import("../components/FileTransfer.vue")
  },
  {
    path:'/autops',
    name:'generate leave note',
    component: ()=> import("../components/GenerateLeave.vue")
  },
  {
    path: '/autops-s',
    name: 'generate ShanWai',
    component: ()=> import("../components/GouRui.vue")
  }

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
